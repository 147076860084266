import React, { useState } from "react";

const Input = () => {
    const [inputs, setInputs] = useState({
            kbsise: "",
            done: "",
            wish: "",            
        });
    
    const {kbsise, done, wish} = inputs;

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
        }

    const onChange = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        setInputs({
            ...inputs,
            [id]: value.replaceAll(",","")
        });
        };
    
    const [max, setMax] = useState("");
    const [rate, setRate] = useState("");
    const [jungdo, setJungdo] = useState("");
    const [monthlypayment, setMonthlypayment] = useState("");
    const assume = () => {
        
        setMax(kbsise * 0.6 - done);
        setRate("14.5%");
        setJungdo("3.0%");
        setMonthlypayment((kbsise * 0.6 - done)*0.145/12);
    }   
    const reset = () => {
        setMax(0);
        setRate(0);
        setJungdo(0);
        setMonthlypayment(0);
        setInputs({kbsise: "", done: "", wish: ""});
    }
    const monthlypayments = (parseInt(monthlypayment)).toLocaleString();
    const maximum = (parseInt(max)).toLocaleString();


    return (
        <div className="input">
            <p><h3>대출가능금액 간편계산(아파트 Only)</h3></p>
            <div>
            <label>1. KB시세 </label>
            <input type="text" style={{width:'100px', textAlign:"right"}} id="kbsise" onChange={onChange} value={addComma(kbsise) || ""} />
            </div>
            <div>
            <label>2. 기대출 선순위 금액 </label>
            <input type="text" style={{width:'100px', textAlign:"right"}} id="done" onChange={onChange} value={addComma(done) || ""} />
            </div>
            <div>
            <label>3. 대출 희망금액 </label>
            <input type="text" style={{width:'100px', textAlign:"right"}} id="wish" onChange={onChange} value={addComma(wish) || ""} />
            </div><br></br>
            
            <button onClick={assume}>계산하기</button>
            
            <hr />
            <p><h3>간편계산 결과</h3></p>
            <p>대출가능금액 : <font color='red'>{maximum}</font>원<br/>
               이자율 : <font color='red'>{rate}</font> (중도상환수수료 : <font color='red'>{jungdo}</font>)<br/>
               예상 월이자 : <font color='red'>{monthlypayments}</font>원<br/>
               대출기간 : 1년</p>            
            <button onClick={reset}>다시계산(Reset)</button>
            <hr></hr>
            <p>자세한 문의사항은 아래의 카카오톡<br />오픈채팅으로 문의바랍니다.</p>
            <p><a href='https://open.kakao.com/o/s8sKv9ng' target='new'><img width='55px' src='/kakao_logo.png' /></a></p>
        </div>

    );
};
export default Input;