import React from "react";
import Input from "./Input";

const Home = () => {

    return(
    
     <table width='350'>
         <thead></thead>
         <tbody>
         <tr>                   
             <td width='100%' align='right' valign='top'><Input /></td>
         </tr></tbody>
         <tfoot></tfoot>
     </table>
    
    );
}
export default Home;